@import "../../assets/css/variables";

.panel {
    position: absolute;
    z-index: 9999999;
    top: 0;
    bottom: 0;
    right: 30px;
    font-size: 12px;
    background: rgba(43,47,48,0.95);
    width: 350px;
    color: #fff;
	h3 {
		font-size: 12px;
		margin: 15px 0;
	}
	.separator {
		height: 1px;
		border-bottom: 1px solid $c-border;
		margin: 15px 0;
	}
    .MuiButtonBase-root {
        height: 30px;
        font-size: 12px !important;
    }
}

.panel__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.panel__scrollbar {
    flex: 1 0 auto;
    height: auto !important;
}

.panel__footer {
    border-top: 1px solid #4a4a4a;
    display: flex;
    flex-direction: column;
    .editor--full & {
		left: -320px;
	}
}

.panel__footer-tools {
    padding: 10px 20px 10px 16px;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s ease;
}

.panel__footer-right {
    display: flex;
    gap: 10px;
}

.panel__footer-left,
.panel__back {
    display: flex;
    align-items: center;
}

.panel__back {
    cursor: pointer;
}

.panel__button-export.MuiButton-contained:not(.Mui-disabled) {
    background: $c-bg-editor-2 !important;
    &:hover {
        background: #000 !important;
    }
}

.panel__logo {  
    display: flex;
    align-items: flex-start;
    padding-left: 20px;   
    padding-top: 30px;
    padding-bottom: 30px;
    svg {
        width: 130px;
    }
    .v2 {
        display: inline-block;
        text-transform: uppercase;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        background-color: $c-body;
        color: $c-dark;
        padding: 1px 5px;
        border-radius: 3px;
        margin-left: 5px;
        transform: translateY(-1px);
    }
}

.panel__notice {
    padding: 5px 20px 20px;
    & > * {
        display: inline;
    }
    svg {
        margin-right: 5px;
        transform: translateY(2px);
    }
}

.panel__upgrade {
    padding: 0 20px 20px;
}

@import "../../assets/css/variables";

.controls {
    color: #fff;
	label {
		display: flex;
        align-items: center;
		margin: 0;
        em {
            display: inline-block;
            text-transform: uppercase;
            font-size: 8px;
            font-style: normal;
            font-weight: 700;
            background-color: $c-body;
            color: $c-dark;
            padding: 1px 5px;
            border-radius: 3px;
            margin-left: 3px;
            transform: translateY(-1px);
        }
	}
    .MuiFormControlLabel-root {
        margin: 0 !important;
    }
	input[type="text"] {
		padding: 10px;
		font-size: 12px;
	}
    .MuiButtonBase-root {
		font-size: 12px !important;
	}
	.MuiRadio-root {
		display: none;
		& + span {
			padding: 5px 5px;
			display: block;
			margin: 0 !important;
			border: none;
            border-radius: 3px;
		}
		&.Mui-checked + span {
			border-color: #3b82f6;
            color: #fff;
            background-color: $c-bg-editor-1;
		}
	}
	.radio-group {
		display: flex;
		align-items: center;
        border-radius: 8px;
        background-color: $c-bg-editor-2;
        border: 1px solid $c-border;
        position: relative;
		&>label {
			flex: 1;
			text-align: center;
            z-index: 1;
            padding: 5px;
            display: block;
		}
	}
	fieldset {
        color: #bfc2c5;
        border-color: #3a3d40;
		border-radius: 0 !important;
        border-radius: 8px !important;
	}
	.MuiInputBase-input {
        border-radius: 8px !important;
        background-color: rgba(36,36,38,0.8);
		padding: 10px !important;
		font-size: 12px !important;
        &::placeholder {
            font-size: 12px !important;
        }
	}
    .MuiFormControl-root,
    .MuiFormGroup-root {
        margin-bottom: 0 !important;
    }
}

.controls__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    user-select: none;
}

.controls__header-label {
	font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .08em;
    text-transform: uppercase;
    color: #fff;
}

.controls__body {
    padding: 0 20px 15px;
    .controls--collapsed & {
        display: none;
    }
}

.controls__field-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    & > * {
        &:first-child {
            width: 60% !important;
        }
        &:last-child {
            width: 40% !important;
            &.full-width {
                width: 100% !important;
            }
        }
    }
    .MuiSwitch-root {
        width: 58px !important;
        margin-right: -10px;
    }
    .MuiSwitch-switchBase {
        top: 4px;
        background: none !important;
    }
    .MuiSelect-select {
        height: 1.4375em !important;
        display: flex;
        align-items: center;
    }
    &.controls__field-group--label-block > * {
        width: 100% !important;
        margin-bottom: 5px;
    }
    textarea {
        background-color: $c-bg-editor-2;
        width: 100%;
        border-radius: 8px;
        padding: 15px;
        border: 1px solid $c-border;
        color: #fff;
        outline: none;
        resize: none;
        font-size: 12px;
        font-family: inherit;
    }
}

.webflowAPIToken {
    background-color: $c-bg-editor-2;
    border-radius: 8px;
}

.controls__field-group--selector .MuiFormControl-root {
    background-color: $c-bg-editor-2;
    border-radius: 8px;
}

label.label-flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.controls__button {
    padding: 10px;
    text-align: center;
    background: $c-bg-editor-2;
    border-radius: 8px;
    border: 1px solid $c-border;
    cursor: pointer;
}

.controls__header-icon svg {
    width: 100%;
    height: 100%;
}

.controls__header-icon {
    width: 16px;
    height: 16px;
    transition: all .3s ease;
    .controls--collapsed & {
        transform: rotate(-90deg);
    }
}

.control-select-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: $c-body;
    pointer-events: none;
}

.control-description {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.controls__advanced {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.controls__advanced-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px !important;
    background-color: rgba(36,36,38,0.8);
    padding: 10px !important;
    font-size: 12px !important;
    cursor: pointer;
    user-select: none;
    svg {
        width: 10px;
        height: 10px;
    }
}

.controls__advanced-content {
    margin-top: 15px;
}

.selector-target {
    font-size: 24px;
    display: flex;
    color: #c5c5c5;
    cursor: pointer;
    &.active {
        color: $c-bl;
    }
}

.react-tagsinput {
    background-color: $c-bg-editor-2 !important;
    border: 1px solid $c-border !important;
    padding: 5px 0 0 5px !important;
    border-radius: 8px;
}

.react-tagsinput-input {
    width: 100px !important;
    margin-bottom: 5px !important;
    color: #fff !important;
    padding: 7px !important;
}

.react-tagsinput-tag {
    background-color: $c-bg-editor-1 !important;
    color: #fff !important;
    border-radius: 3px !important;
    padding: 6px !important;
    border: none !important;
    font-size: 12px !important;
    font-family: Inter !important;
}

.react-tagsinput-remove {
    padding: 5px;
}

.code-wrapper {
    height: 105px;
    overflow: auto;
    background-color: $c-bg-editor-2;
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid $c-border;
    .dialog__content & {
        background-color: $c-bg-editor-3;
    }
    /* width */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $c-bg-editor-2;
		border-radius: 0 8px 8px 0;
        .dialog__content & {
            background: $c-bg-editor-3;
        }
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #555;
		border-radius: 8px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.w-tc-editor {
    background: none !important;
    overflow: auto !important;
    textarea {
        padding: 0 !important;
        border-radius: 0 !important;
        min-height: 40px !important;
    }
}

.w-tc-editor-preview {
    padding: 0 !important;
    min-height: 40px !important;
    .punctuation {
        color: #bb78c1 !important;
    }
}

.invalid-message {
    font-size: 12px;
    margin-top: 5px;
    display: none;
    .error & {
        display: block;
    }
}

.full-width {
    width: 100% !important;
}

.promotion {
    padding: 10px 20px;
    display: flex;
    background: #ffffff0d;
}

.promotion__logo {
    width: 30px;
    height: 36px;
    margin-right: 10px;
}